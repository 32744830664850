import React, {
	useState, useCallback, useRef, createContext, useContext
} from "react";
import _ from "lodash";
import uuid4 from "common/uuidv4";
import axios from "axios";
import useAuth from "./useAuth";

export const TreeContext = createContext({});

export const TreeProvider = ({ children }) => {
	const rootFolderIdRef = useRef(uuid4());
	const { customHeaders } = useAuth();
	const [folderTree, setFolderTree] = useState();

	const checkChildren = useCallback((treeNode, nodeId, resolve) => {
		if (!treeNode.name) {
			Object.values(treeNode).forEach((c) => {
				if (c.id === nodeId) {
					resolve(c);
				}
				if (c.children && Object.keys(c.children).length > 0) {
					checkChildren(c.children, nodeId, resolve);
				}
			});
		}
		else {
			if (treeNode.id === nodeId) {
				resolve(treeNode);
			}

			if (treeNode.children && Object.keys(treeNode.children).length > 0) {
				checkChildren(treeNode.children, nodeId, resolve);
			}
		}
	}, []);

	const findTreeNode = useCallback((nodeId, searchTree) => new Promise((resolve, reject) => {
		try {
			checkChildren(searchTree[rootFolderIdRef.current], nodeId, resolve);
		}
		catch (e) {
			reject(e);
		}
	}), [checkChildren]);

	const fetchFolder = useCallback(async (parentId, refetch = false) => {
		const newTree = _.cloneDeep(folderTree);
		const node = await findTreeNode(parentId, newTree);
		const { nodePath, expanded, depth } = node;
		if (!expanded || refetch) {
			const fileInfo = [
				{ name: "New folder", type: "directory", mtime: "Thu, 12 Aug 2021 14:11:47 GMT" },
				{ name: "TestFolder", type: "directory", mtime: "Thu, 12 Aug 2021 15:18:41 GMT" },
				{
					name: "0001016A.dcm", type: "file", mtime: "Thu, 12 Aug 2021 09:26:14 GMT", size: 29567758
				},
				{
					name: "1_CrCl Disease & types of tears.pdf", type: "file", mtime: "Thu, 12 Aug 2021 09:26:14 GMT", size: 3252591
				},
				{
					name: "TestImage.png", type: "file", mtime: "Thu, 12 Aug 2021 09:26:14 GMT", size: 7645951
				},
				{
					name: "Contract.docx", type: "file", mtime: "Thu, 12 Aug 2021 09:26:14 GMT", size: 13587602
				},
				{
					name: "example.pdf", type: "file", mtime: "Thu, 12 Aug 2021 09:26:14 GMT", size: 4766573
				},
				{
					name: "spreadsheet.xlsx", type: "file", mtime: "Thu, 12 Aug 2021 09:44:43 GMT", size: 170
				},
				{
					name: "folderLinks.json", type: "file", mtime: "Thu, 12 Aug 2021 09:44:43 GMT", size: 170
				},
				{
					name: "folderInfo.json", type: "file", mtime: "Thu, 12 Aug 2021 09:44:43 GMT", size: 170
				}
			];
			// axios.get(nodePath, { headers: customHeaders }).then(({ data: fileInfo }) => {
			const treeTemp = {};
			fileInfo?.forEach?.(async (item) => {
				const { name: itemName, type } = item;
				const id = uuid4();
				treeTemp[id] = {
					...item,
					nodePath: `${nodePath}${itemName}${type === "directory" ? "/" : ""}`,
					id,
					depth: depth + 1
				};
			});
			// eslint-disable-next-line no-param-reassign
			node.children = treeTemp;
			node.expanded = true;
			setFolderTree(newTree);
			// }).catch((e) => {
			// 	console.error("Couldn't get the folder listing", e);
			// });
		}
		else {
			node.expanded = false;
			setFolderTree(newTree);
		}
	}, [findTreeNode, folderTree]);

	const initialiseTree = useCallback((basePath) => {
		setFolderTree({
			[rootFolderIdRef.current]: {
				name: "Documents", type: "directory", mtime: "", id: rootFolderIdRef.current, nodePath: `${basePath}/docs/`, depth: 0
			}
		});
	}, []);

	const resetTree = useCallback(() => {
		setFolderTree(undefined);
	}, []);

	const values = {
		initialiseTree,
		folderTree,
		fetchFolder,
		resetTree
	};

	return <TreeContext.Provider value={values}>{children}</TreeContext.Provider>;
};

const useFolderTree = () => {
	const context = useContext(TreeContext);
	if (context === undefined) {
		throw new Error("useFolderTree must be used within a TreeProvider");
	}

	return context;
};

export default useFolderTree;
