import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useDialog from "hooks/useDialog";

const StyledComponent = styled.div`
	.alertDialog {
		width: 100%;
		margin-top: auto;
		margin-left: auto;
		font-size: initial;
	}

	.content {
		display: flex;
		flex-direction: column;
		width: fit-content;
		background-color: var(--white);
		box-shadow: var(--box-shadow);
		border-radius: 0.25rem;
		padding: 2rem;
	}

	.modal {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		background: var(--modal-background);
		backdrop-filter: blur(3px);
		word-wrap: break-word;
	}

	.resetHeight {
		height: initial;
	}

	.header {
		font-weight: 700;
	}

	hr {
		width: 100%;
		border: none;
		border-top: 1px solid var(--lines-color);
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		min-width: 200px;
		margin-top: 2rem;

		.button {
			flex: 1;
			max-width: 100%;
			border-left: 0;
			border-right: 0;
			border-radius: 0;
			margin-right: 5px;
			margin-top: 0;
			margin-bottom: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.button:first-of-type {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}

		.button:last-of-type {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
			margin-right: 0;
		}
	}

	.buttonDiv {
		display: flex;
		align-content: center;
		justify-content: center;
	}
`;

const AlertDialog = (props) => {
	const { children } = props;
	return (
		<StyledComponent>
			<div id="alert-dialog" className={["modal", "alertDialog"].join(" ")}>
				{children}
			</div>
		</StyledComponent>
	);
};

export default AlertDialog;

export const InfoDialog = ({
	title,
	message,
	okAction,
	cancelAction,
	buttonTitles
}) => {
	const { dismissDialog } = useDialog();
	const defaultAction = (ev) => {
		if (ev && ev.key && ev.key !== "Enter") {
			return;
		}
		okAction?.();
		dismissDialog();
	};

	const doCancel = () => {
		cancelAction?.();
		dismissDialog();
	};

	useEffect(() => {
		document.addEventListener("keydown", defaultAction);

		return () => {
			document.removeEventListener("keydown", defaultAction);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let buttons;
	if (okAction) {
		buttons = [
			<div key="buttonGroup" className="buttons">
				<button key="cancelButton" id="cancelButton" onClick={doCancel} type="button">{buttonTitles.cancel}</button>
				<button
					id="okButton"
					key="okButton"
					onClick={defaultAction}
					type="button"
				>
					{buttonTitles.ok}
				</button>
			</div>
		];
	}
	else {
		buttons = [<button type="button" id="cancelButton" key="cancelButton" onClick={doCancel}>Ok</button>];
	}
	return (
		<StyledComponent>
			<AlertDialog>
				<div className={["content", "resetHeight"].join(" ")}>
					<div className="header">
						{title}
					</div>
					<hr />
					<div>
						<div>{message}</div>
					</div>
					<div>
						<div className="buttonDiv">{ buttons }</div>
					</div>
				</div>
			</AlertDialog>
		</StyledComponent>
	);
};

InfoDialog.defaultProps = {
	okAction: null,
	cancelAction: null,
	buttonTitles: { ok: "Ok", cancel: "Cancel" }
};

InfoDialog.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	okAction: PropTypes.func,
	cancelAction: PropTypes.func,
	buttonTitles: PropTypes.shape({
		ok: PropTypes.string,
		cancel: PropTypes.string
	})
};

export function infoDialog(title, message, okAction = null, cancelAction = null, buttonTitles) {
	return (
		<InfoDialog
			title={title}
			message={message}
			okAction={okAction}
			cancelAction={cancelAction}
			buttonTitles={buttonTitles}
		/>
	);
}

export const EditDialog = ({
	title,
	okAction,
	children
}) => {
	const { dismissDialog } = useDialog();
	const defaultAction = (ev) => {
		if (ev && ev.key && ev.key !== "Enter") {
			return;
		}

		okAction?.();
		dismissDialog();
	};

	useEffect(() => {
		document.addEventListener("keydown", defaultAction);

		return () => {
			document.removeEventListener("keydown", defaultAction);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledComponent>
			<AlertDialog>
				<div className={["content", "resetHeight"].join(" ")}>
					<div className="header">
						{title}
					</div>
					<hr />
					<div>
						<div>{children}</div>
					</div>
					<div>
						<div>
							{ okAction
								? (
									<div className="buttons">
										<button type="button" id="cancelButton" onClick={dismissDialog}>Cancel</button>
										<button
											type="button"
											id="okButton"
											onClick={() => {
												okAction(); dismissDialog();
											}}
										>
											Ok
										</button>
									</div>
								)
								: (
									<button type="button" id="okButton" onClick={dismissDialog}>Ok</button>
								)}
						</div>
					</div>
				</div>
			</AlertDialog>
		</StyledComponent>
	);
};

EditDialog.defaultProps = {
	okAction: null
};

EditDialog.propTypes = {
	title: PropTypes.string.isRequired,
	okAction: PropTypes.func,
	children: PropTypes.element.isRequired
};
