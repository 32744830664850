import React from "react";
import useDialog from "hooks/useDialog";

const ModalController = () => {
	const { dialogStack, modalStack } = useDialog();
	const dialog = dialogStack?.length ? dialogStack[dialogStack.length - 1] : null;
	return (
		<>
			{dialog}
			{
				modalStack ? modalStack.map((modal) => (modal)) : null
			}
		</>
	);
};

export default ModalController;
