import React from "react";
import { LoginProvider } from "hooks/useAuth";
import { DialogProvider } from "hooks/useDialog";
import { TreeProvider } from "hooks/useFolderTree";
import DialogController from "components/dialogs/DialogController";
import ModalController from "components/dialogs/ModalController";

const WrapperComponent = ({ element }) => (
	<DialogProvider>
		<LoginProvider>
			<TreeProvider>
				{element}
				<ModalController />
				<DialogController />
			</TreeProvider>
		</LoginProvider>
	</DialogProvider>
);

export default WrapperComponent;
