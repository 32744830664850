import React, {
	useMemo, useReducer, useCallback, createContext, useContext
} from "react";
import { produce } from 'immer';
import uuidv4 from "common/uuidv4";

const dialogReducer = produce((draft, action) => {
	switch (action.type) {
		case "present": {
			draft.push(action.dialog);
			break;
		}
		case "dismiss": {
			draft.pop();
			break;
		}
		case "clear": {
			draft.splice(0, draft.length);
			break;
		}
		default: break;
	}
});

export const DialogContext = createContext({
	presentDialog: () => {
		throw new Error("Called presentDialog uninitialised");
	},
	dismissDialog: () => {
		throw new Error("Called dismissDialog uninitialised");
	},
	presentModal: () => {
		throw new Error("Called presentModal uninitialised");
	},
	dismissModal: () => {
		throw new Error("Called dismissModal uninitialised");
	},
	dismissAll: () => {
		throw new Error("Called dismissAll uninitialised");
	},
	resetDialogStack: () => {
		throw new Error("Called resetDialogStack uninitialised");
	},
	resetModalStack: () => {
		throw new Error("Called resetModalStack uninitialised");
	},
	dialogStack: [],
	modalStack: []
});

export const DialogProvider = ({ children }) => {
	const dialogInitialStack = [];
	const [dialogState, dispatchDialog] = useReducer(dialogReducer, dialogInitialStack);
	const [modalState, dispatchModal] = useReducer(dialogReducer, dialogInitialStack);

	const presentDialog = useCallback((dialog) => {
		const id = uuidv4();
		dispatchDialog({ type: "present", dialog });
		return id;
	}, []);

	const dismissDialog = useCallback(() => {
		dispatchDialog({ type: "dismiss" });
	}, []);

	const presentModal = useCallback((dialog) => {
		const id = uuidv4();
		dispatchModal({ type: "present", dialog });
		return id;
	}, []);

	const dismissModal = useCallback(() => {
		dispatchModal({ type: "dismiss" });
	}, []);

	const resetDialogStack = useCallback(() => {
		dispatchDialog({ type: "clear" });
	}, []);

	const resetModalStack = useCallback(() => {
		dispatchModal({ type: "clear" });
	}, []);

	const dismissAll = useCallback(() => {
		dispatchDialog({ type: "clear" });
		dispatchModal({ type: "clear" });
	}, []);

	const dialogStack = useMemo(() => [...dialogState], [dialogState]);
	const modalStack = useMemo(() => [...modalState], [modalState]);

	const values = {
		presentDialog,
		dismissDialog,
		presentModal,
		dismissModal,
		resetDialogStack,
		resetModalStack,
		dismissAll,
		dialogStack,
		modalStack
	};

	return <DialogContext.Provider value={values}>{children}</DialogContext.Provider>;
};

DialogProvider.defaultProps = {
	children: null
};

const useDialog = () => {
	const context = useContext(DialogContext);
	if (context === undefined) {
		throw new Error("useDialog must be used within a DialogProvider");
	}

	return context;
};

export default useDialog;
