import React, {
	useContext, createContext, useState, useMemo, useEffect, useCallback, useRef
} from "react";
import _ from "lodash";
import axios from "axios";
import { server, masterKey } from "common/app-settings";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import packageJson from "../../package.json";

const LoginContext = createContext();

/**
 * This component uses the axios component directly instead of the api hook.
 * This ie because it has to do a few different things, which would have added
 * needless complexity to the useApi hook
*/
export const LoginProvider = ({ children }) => {
	const [user, setUser] = useState();
	const [token, setToken] = useState(masterKey);
	const isBrowser = useMemo(() => typeof window !== "undefined", []);
	const intRef = useRef();
	const initialLoad = useRef(true);
	const { presentDialog } = useDialog();

	const isLoggedIn = useMemo(() => user?.email, [user?.email]);

	const checkLogin = useCallback(() => {
		if (isLoggedIn) {
			axios.get(`${server}/auth/token`, { headers: customHeaders })
				.then(({ data: usr }) => {
					window.localStorage.setItem("gatsbyUser", JSON.stringify(usr));
					setUser(usr);
				})
				.catch((e) => {
					setUser([]);
					presentDialog(infoDialog("Error", `Could not get token, please log in again ${e}`));
				});
		}
	}, [isLoggedIn, customHeaders, presentDialog]);

	useEffect(() => {
		const usr = window.localStorage.getItem("gatsbyUser");
		const tkn = window.localStorage.getItem("gatsbyToken");
		setToken(tkn ?? masterKey);
		if (tkn && usr) {
			try {
				const decoded = JSON.parse(usr);
				if (!_.isEqual(user, decoded)) {
					setUser(decoded);
				}
			}
			catch (e) {
				console.error("ERROR", e);
				presentDialog(infoDialog("Error", `Invalid token, please log in again ${e}`));
			}
		}
	}, [checkLogin, presentDialog, user]);

	useEffect(() => {
		if (initialLoad.current && isLoggedIn) {
			checkLogin();
			initialLoad.current = false;
		}
	}, [checkLogin, isLoggedIn]);

	const customHeaders = useMemo(() => ({
		accept: "application/json",
		Authorization: `Bearer ${token}`,
		"X-Client-Version": packageJson.version
	}), [token]);

	useEffect(() => {
		if (token !== masterKey && !intRef.current) {
			intRef.current = setInterval(() => checkLogin(), 300000);
		}

		return () => {
			clearInterval(intRef.current);
			intRef.current = null;
		};
	}, [checkLogin, token]);

	const handleLogin = ({ email, password }) => {
		const authorization = btoa(`${email}:${password}`);

		const customHeader = {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Basic ${authorization}`,
		};

		const body = `access_token=${masterKey}&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;

		return axios.post(`${server}/auth/login`, body, { headers: customHeader }).then(({ data: { user: usr, token: tkn } }) => {
			setUser(usr);
			setToken(tkn);
			if (isBrowser) {
				window.localStorage.setItem("gatsbyUser", JSON.stringify(usr));
				window.localStorage.setItem("gatsbyToken", tkn);
			}
		}).catch((e) => {
			console.error("LOGIN FAILED", e);
			presentDialog(infoDialog("Login Failed", `The login failed: ${e.message}`));
		});
	};

	const logout = (callback) => {
		if (isBrowser) {
			window.localStorage.removeItem("gatsbyUser");
			window.localStorage.removeItem("gatsbyToken");
			window.localStorage.removeItem("lastProject");
		}
		clearInterval(intRef.current);
		intRef.current = null;
		setUser(undefined);
		setToken(undefined);
		callback?.();
	};

	const handlePasswordResetRequest = ({ email }) => {
		axios.post(`${server}/reset-password`, { email }, { headers: { Authorization: `Bearer ${masterKey}` } })
			.then(() => {
				presentDialog(infoDialog("Email sent", "A link has been sent to your registered email address, please follow the instructions there"));
			})
			.catch((e) => {
				console.error("FAILED TO REQUEST PASSWORD RESET", e);
				presentDialog(infoDialog("Error", `Could not request a password reset. Did you use the correct email address? ${e}`));
			});
	};

	const resetPassword = (resetPasswordInfo) => {
		const customHeader = {
			Authorization: `Bearer ${resetPasswordInfo.token}`,
		};

		axios.put(`${server}/reset-password/${resetPasswordInfo.token}`, resetPasswordInfo, { headers: customHeader })
			.then(() => {
				presentDialog(infoDialog("Password Reset", "Your password has been sucessfully reset"));
			})
			.catch((err) => {
				console.error("ERROR", err);
				presentDialog(infoDialog("Error", `Could not reset your password: ${err}`));
			});
	};

	const values = {
		handleLogin,
		isLoggedIn,
		user,
		logout,
		token,
		handlePasswordResetRequest,
		resetPassword,
		customHeaders
	};

	return (
		<LoginContext.Provider value={values}>{children}</LoginContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(LoginContext);
	if (context === undefined) {
		throw new Error("Must use Login Context inside the provider!");
	}

	return context;
};

export default useAuth;
