import React from "react";
import useDialog from "hooks/useDialog";

const DialogController = () => {
	const { dialogStack } = useDialog();
	const dialog = dialogStack.length ? dialogStack[dialogStack.length - 1] : null;
	return <div>{dialog}</div>;
};

export default DialogController;
